import {Event} from '@wix/ambassador-wix-events-web/types'
import {getSlug} from '../services/location'
import {createAsyncAction} from '../services/redux-toolkit'

export const getEvent = createAsyncAction<Event>('GET_EVENT', (_, {extra: {api, controllerParams}}) => {
  const slug = getSlug(controllerParams)
  return slug ? api.getEventBySlug(slug).then(event => event || api.getEvent()) : api.getEvent()
})

export const getDemoEvent = createAsyncAction<Event, {timeZoneId: string; language: string}>(
  'GET_DEMO_EVENT',
  async ({timeZoneId, language}, {extra: {api}}) => api.getDemoEvent(timeZoneId, language),
)

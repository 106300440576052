import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import {BiParams, createUouBiMiddlewareWithBiParams, getFormattedFullLocation} from '@wix/wix-events-commons-statics'
import {getFormattedFullDate} from '@wix/events-moment-commons'
import type {ControllerParams, CreateControllerFn} from '@wix/yoshi-flow-editor'
import {bindActionCreators} from 'redux'
import * as eventsUou from './bi/uou-bi-events-map'
import {getEvent, getDemoEvent} from './actions/event'
import {getSchedule, getDemoSchedule, readDescriptionClicked} from './actions/schedule'
import {createApi} from './api/api'
import {rootReducer, State} from './reducers'
import {getEventDetailsUrl} from './services/location'
import {getDays, getLocations, getTags} from './services/schedule'
import {EventScheduleProps} from './Widget/interfaces'
import {toggleScheduleItemModal, toggleFiltersModal} from './actions/modals'
import {toggleTag, setLocation, resetFilter, setFilter} from './actions/filter'
import {schedulePageLoaded} from './actions/page-loaded'
import {decodeInstance} from './services/instance'
import {navigateToEventDetails} from './actions/navigate'

const createController: CreateControllerFn = async (controllerParams: ControllerParams) => {
  return {
    async pageReady() {
      const api = createApi(controllerParams)
      const {wixCodeApi, platformAPIs, appParams, compId} = controllerParams.controllerConfig
      const decodedInstance = decodeInstance(appParams.instance)
      const biMiddleware = createBiMiddleware({
        wixCodeApi,
        platformAPIs,
        appParams,
        compId,
        user: {
          aid: decodedInstance.aid,
          uid: decodedInstance.uid,
        },
      })

      const store = configureStore({
        reducer: rootReducer,
        middleware: [...biMiddleware, ...getDefaultMiddleware({thunk: {extraArgument: {api, controllerParams}}})],
      })

      await store.dispatch(getEvent() as any)

      if (store.getState().event?.id) {
        await store.dispatch(getSchedule() as any)
      }

      const state = store.getState()
      const totalScheduleItems = state.schedule.total

      const showDemoSchedule = wixCodeApi.window.viewMode === 'Editor' && (!state.event || totalScheduleItems === 0)

      if (showDemoSchedule) {
        const timeZoneId = state.event.scheduling?.config?.timeZoneId ?? getTimeZone(controllerParams)
        const language = controllerParams.flowAPI.environment.language
        await store.dispatch(getDemoEvent({timeZoneId, language}) as any)
        await store.dispatch(getDemoSchedule({timeZoneId, language}) as any)
      }

      const actions = bindActionCreators(
        {
          toggleTag,
          setFilter,
          setLocation,
          toggleScheduleItemModal,
          toggleFiltersModal,
          resetFilter,
          readDescriptionClicked,
          navigate: navigateToEventDetails,
        },
        store.dispatch,
      )
      const props = {...(await getProps(store.getState(), controllerParams)), ...actions}
      controllerParams.flowAPI.controllerConfig.setProps(props)

      if (wixCodeApi.window.viewMode === 'Site') {
        store.dispatch(schedulePageLoaded({totalScheduleItems}))
      }

      if (state.error) {
        controllerParams.flowAPI.reportError(state.error.message)
      }

      store.subscribe(async () => {
        const newState = store.getState()
        const error = newState.error
        const newProps = await getProps(newState, controllerParams)

        if (error) {
          controllerParams.flowAPI.reportError(error.message)
        }

        controllerParams.flowAPI.controllerConfig.setProps(newProps)
      })
    },
  }
}

const getProps = async (state: State, controllerParams: ControllerParams): Promise<EventScheduleProps> => {
  const {event, schedule} = state
  const controllerConfig = controllerParams.controllerConfig
  const wixCodeApi = controllerConfig.wixCodeApi
  const {t} = controllerParams.flowAPI.translations
  const eventDetailsUrl = await getEventDetailsUrl(controllerParams, event)
  const locale = wixCodeApi.site.regionalSettings
  const {location: selectedLocation, tags: selectedTags} = state.filter
  const locations = getLocations(schedule, t)
  const modals = state.modals
  const filter = state.filter
  const isSSR = controllerParams.flowAPI.environment.isSSR

  return {
    formattedEventDateAndLocation: isSSR
      ? ''
      : `${getFormattedFullDate(event, locale, false)} ${getFormattedFullLocation(event)}`,
    eventTitle: event.title,
    eventDetailsUrl,
    locations,
    tags: getTags(schedule, selectedTags),
    days: getDays(schedule, locale, isSSR),
    selectedLocation,
    modals,
    filter,
    scheduleTotal: schedule.total,
    isSite: wixCodeApi.window.viewMode === 'Site',
  }
}

const getTimeZone = (controllerParams: ControllerParams) => {
  return typeof Intl === 'object' && typeof Intl.DateTimeFormat === 'function'
    ? Intl.DateTimeFormat().resolvedOptions().timeZone
    : controllerParams.controllerConfig.wixCodeApi.site.timezone
}

const createBiMiddleware = (biParams: BiParams) => [createUouBiMiddlewareWithBiParams(biParams, eventsUou)]

export default createController
